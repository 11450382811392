import { TCalcs, TItem } from "@liminil/estreon-sdk";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { GraphState } from "../redux/graph.slice";
import { AppState } from "../redux/store";
import { fixDate } from "./graph";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { translate } from "../common";


export function TDataTable() {

    const graphState = useSelector<AppState>(state => state.graph) as GraphState;
    const [_data, setData] = useState<TItem[]>([])

    useEffect(() => {
        if (graphState.activeDate) {
            let date = fixDate(graphState.activeDate)
            let service = new TCalcs()
            let items = service.execute(date)
            setData(items)
        }
    }, [graphState.activeDate])

    const nameBodyTemplate = (item: TItem) => {
        let prefix = item.index > 0 ? '+' : '';

        return <span>{`T${prefix}${item.index}`}</span>
    }

    const percentBodyTemplate = (item: TItem) => {
        let value = item.data[0];
        let percent = 0;
        if (value) {
            percent = item.data[0] * 100;
        }
        return <span>{`${percent.toFixed(2)}%`}</span>
    }

    const ratioBodyTemplate = (item: TItem) => {
        return <span>{item.data[1]?.toFixed(2)}</span>
    }

    const seedTemplate = (item: TItem) => {
        return <span>{item.seed?.toFixed(2)}</span>
    }

    const columnGroup = (
        <ColumnGroup>
            <Row>
                <Column header={translate("EXTENDED_CYCLES")} colSpan={4}></Column>
            </Row>
            <Row>
            <Column header={''} colSpan={2}></Column>
            <Column header={translate("PHASE")} colSpan={1}></Column>
            <Column header={translate("YEAR")} colSpan={1}></Column>
            </Row>

        </ColumnGroup>
    )


    return (
        <DataTable value={_data} size="small" headerColumnGroup={columnGroup} className="tcalc-table">
            <Column body={nameBodyTemplate}></Column>
            <Column body={seedTemplate}></Column>
            <Column body={percentBodyTemplate}></Column>
            <Column  body={ratioBodyTemplate}></Column>

        </DataTable>
    )

}