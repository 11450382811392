import { Calendar } from 'primereact/calendar';
import { Fieldset } from 'primereact/fieldset';
import { InputSwitch, InputSwitchChangeEvent } from 'primereact/inputswitch';
import { useDispatch, useSelector } from 'react-redux';
import ReactSlider from 'react-slider';
import { translate } from '../common';
import { GraphState, loadMatrix, setActive, setEndDate, setInterval, setStartDate, setStep } from '../redux/graph.slice';
import { AppState } from '../redux/store';
import { ControlDataCache } from '../services/control-data-cache.service';
import settings from '../settings.json';

export interface ControlBoxProps {
    className?: string
}


export function ControlBox(props: ControlBoxProps) {

    const dispatch = useDispatch<any>();
    const graphState = useSelector<AppState>(state => state.graph) as GraphState;




    const renderIntervalThumb = (props: any, state: any) => {
        const str = (state.value / 1000).toFixed(1)

        return (<div {...props}>{str}</div>)
    }

    const renderStepThumb = (props: any, state: any) => {
        const str = state.value;

        return (<div {...props}>{str}</div>)
    }

    const handleStartDatesChange = (e: any) => {
        let date = e.value;
        if (date instanceof Date) {
            dispatch(setStartDate(date))
            dispatch(loadMatrix(date))
        }
    }

    const handleEndDatesChange = (e: any) => {
        let date = e.value;
        if (date instanceof Date) {
            dispatch(setEndDate)
        }
    }

    const handleIntervalChanged = (v: number, i: number) => {
        ControlDataCache.interval = v;
        dispatch(setInterval(v))
    }

    const handleStepChanged = (v: number, i: number) => {
        ControlDataCache.step = v;
        dispatch(setStep(v))
    }

    const handleActiveChanged = (e: InputSwitchChangeEvent) => {
        ControlDataCache.active = e.value
        dispatch(setActive(e.value))

    }


    const header = (
        <span className='control-box-header'>
            <label style={{ marginRight: 10 }}>{translate("ACTIVE")}</label>

            <InputSwitch className="active-input-switch" checked={graphState.active ?? false} onChange={handleActiveChanged} />

        </span>
    )

    return (
        <div style={{ width: '100%', height: '100%' }} className={props.className ?? '' + 'control-box'}>
            <Fieldset legend={translate("DATES")} className="control-box-dates">
                <div className="date-block">
                    <label >{translate("START_DATE")}</label>
                    <Calendar className="control-box-calendar" hideOnDateTimeSelect={true} id="start-date" value={graphState.startDate} onChange={handleStartDatesChange} showTime hourFormat="24" />
                </div>
                <div className="date-block" style={{ marginTop: 4 }}>
                    <label >{translate("END_DATE")}</label>
                    <Calendar className="control-box-calendar" hideOnDateTimeSelect={true} id="end-date" value={graphState.endDate} onChange={handleEndDatesChange} showTime hourFormat="24" />
                </div>

            </Fieldset>

            <Fieldset legend={header}>

                <div className="field">
                    <label htmlFor="time-interval">{translate("TIME_INTERVAL")}</label>

                    <ReactSlider className="horizontal-slider control-box-slider"
                        thumbClassName="thumb"
                        trackClassName="track" min={settings.interval.min} max={settings.interval.max} step={settings.interval.step} value={graphState.interval} onChange={handleIntervalChanged}
                        renderThumb={renderIntervalThumb} />
                </div>
                <div className="field">
                    <label htmlFor="increment">{translate("INCREMENT")}</label>
                    <ReactSlider className="horizontal-slider control-box-slider"
                        thumbClassName="thumb"
                        trackClassName="track" min={settings.step.min} max={settings.step.max} step={settings.step.step} value={graphState.step} onChange={handleStepChanged}
                        renderThumb={renderStepThumb} />
                </div>


            </Fieldset >
        </div>
    )
}